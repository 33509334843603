import React, { useState, useEffect } from 'react';
import {
  defaultOTSState,
  FindStatus,
  SwitchTile,
} from '@utilitywarehouse/quote-ui';
import PropTypes from 'prop-types';
import {
  oneTouchSwitchSection,
  referralAndLegalSection,
} from 'app/redux/modules/YourDetails/form';
import { formatAddressLines } from './formatAddress';

import { useOneTouchSwitchTrackEvents } from './useOneTouchSwitchTrackEvents';
import { FIND_STATUS } from '../constants';
const useOneTouchSwitchState = (
  fields,
  updateForm,
  termsAndConditionsState,
  hasBroadband,
  supplyAddress,
  surname,
  orderType,
  numberToPort,
  isSurnameValid,
  updateBothForms
) => {
  const {
    HAS_EXISTING_LINE,
    CURRENT_PROVIDER,
    PROVIDER_NOT_LISTED,
    FIND_STATUS,
    MATCH_ID,
    SWITCHING_REFERENCE,
    BROADBAND_REFERENCE,
  } = oneTouchSwitchSection;
  const [rawState, setRawState] = useState({
    ...defaultOTSState,
    surname,
    currentAddress: {
      uprn: supplyAddress.uprn,
      addressLines: formatAddressLines(supplyAddress.paf),
      postCode: supplyAddress.paf.postcode,
      postTown: supplyAddress.paf.postTown,
    },
    orderType: orderType,
    numberToPort: numberToPort,
    canSubmit: isSurnameValid,
  });

  const handleFormUpdate = (field, value) => {
    if (termsAndConditionsState) {
      updateBothForms(
        [{ field, value }],
        [{ field: referralAndLegalSection.TERMS_AND_CONDITIONS, value: false }]
      );
    } else {
      updateForm(field, value);
    }
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      canSubmit: isSurnameValid,
    }));
  }, [isSurnameValid]);

  // Update `surname` based on the new `surname` prop
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      surname: surname,
    }));
  }, [surname]);
  // update form validation redux
  useEffect(() => {
    if (!hasBroadband) {
      updateForm(HAS_EXISTING_LINE, false);
      setState((prevState) => ({
        ...prevState,
        hasExistingLine: false,
      }));
    }
  }, [hasBroadband, updateForm, HAS_EXISTING_LINE]);

  const setState = (state) => {
    handleFormUpdate(HAS_EXISTING_LINE, state.hasExistingLine);
    handleFormUpdate(CURRENT_PROVIDER, state.selectedProvider);
    handleFormUpdate(PROVIDER_NOT_LISTED, state.providerNotListed);
    updateForm(FIND_STATUS, state.findStatus);
    updateForm(MATCH_ID, state.matchId);
    updateForm(SWITCHING_REFERENCE, state.switchingReference);
    updateForm(BROADBAND_REFERENCE, state.broadbandReference);
    setRawState(state);
  };

  return [
    {
      ...rawState,
      hasExistingLine: fields[HAS_EXISTING_LINE] ?? true,
      providerNotListed: fields[PROVIDER_NOT_LISTED] ?? false,
      selectedProvider: fields[CURRENT_PROVIDER] ?? null,
      findStatus: fields[FIND_STATUS] ?? FindStatus.NOT_STARTED,
      matchId: fields[MATCH_ID] ?? null,
      switchingReference: fields[SWITCHING_REFERENCE] ?? null,
      broadbandReference: fields[BROADBAND_REFERENCE] ?? '',
    },
    setState,
  ];
};

const OneTouchSwitch = ({
  fields,
  updateForm,
  supplyAddress,
  termsAndConditionsState,
  surname,
  hasBroadband,
  orderType,
  numberToPort,
  isSurnameValid,
  updateBothForms,
}) => {
  const [state, setState] = useOneTouchSwitchState(
    fields,
    updateForm,
    termsAndConditionsState,
    hasBroadband,
    supplyAddress,
    surname,
    orderType,
    numberToPort,
    isSurnameValid,
    updateBothForms
  );
  const onContinue = () => {
    // eslint-disable-next-line
    console.log('DEV');
  };

  const [firstAttemptFailed, setFirstAttemptFailed] = useState(false);

  useEffect(() => {
    if (state.findStatus === FIND_STATUS.NOT_FOUND) {
      setFirstAttemptFailed(true);
    }
  }, [state.findStatus]);

  useOneTouchSwitchTrackEvents(state, firstAttemptFailed);

  return (
    <SwitchTile state={state} setState={setState} onContinue={onContinue} />
  );
};

OneTouchSwitch.propTypes = {
  updateForm: PropTypes.func.isRequired,
  fields: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ).isRequired,
  supplyAddress: PropTypes.shape({
    firstLine: PropTypes.string,
    postcode: PropTypes.string,
  }).isRequired,
  onTermsChecked: PropTypes.func.isRequired,
  termsAndConditionsState: PropTypes.bool,
  hasBroadband: PropTypes.bool.isRequired,
  surname: PropTypes.string.isRequired,
  orderType: PropTypes.string,
  numberToPort: PropTypes.bool || PropTypes.string,
  updateBothForms: PropTypes.func.isRequired,
};

export default OneTouchSwitch;
